<template>
  <div class="home">
    <!-- <HelloWorld msg="" /> -->
    <h1>
      <div class="welcome">Hi! Welcome to</div>
      <div class="cookout">Peter's Cookout</div>
      <div class="diner">Diner</div>
      <div class="emoji">👨‍🍳🥩♨️</div>
    </h1>
    <router-link class="courses-link" to="/courses">View Menu</router-link>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    // HelloWorld,
  },
};
</script>

<style lang="scss">
$sign-color: #00ffff;
$sign-color-alt: #42b983;
$diner-color: #ff0000;
$shadow-color: #2c3e50;
$shadow-color-light: #42b983;
$background-color: #f1c40f;
// $background-color: #f1c40f;

.emoji {
  font-size: 4rem;

  &.large {
    font-size: 6rem;
  }
}

.home {
  padding-bottom: 4rem;
}

h1 {
  background-color: $background-color;
  color: $sign-color-alt;
  padding: 3rem;
  text-shadow: 1px 1px $shadow-color, 2px 2px $shadow-color,
    3px 3px $shadow-color, 4px 4px $shadow-color, 5px 5px $shadow-color,
    6px 6px $shadow-color, 7px 7px $shadow-color, 8px 8px $shadow-color,
    8px 8px $shadow-color;

  & .welcome {
    color: $sign-color;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 4rem;
    font-family: "Yanone Kaffeesatz", sans-serif;
    animation: flicker 5s infinite;

    @keyframes flicker {
      0%,
      19.999%,
      22%,
      62.999%,
      64%,
      64.999%,
      70%,
      100% {
        //text-shadow: none;
        opacity: 0.99;
      }

      20%,
      21.999%,
      63%,
      63.999%,
      65%,
      69.999% {
        opacity: 0.4;
      }
    }
  }

  & .cookout {
    font-family: "Dancing Script", cursive;
    font-size: 6rem;
    font-weight: 750;
  }

  & .diner {
    color: $diner-color;
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-size: 8rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $diner-color;
    text-shadow: 1px 1px $shadow-color, 2px 2px $shadow-color,
      3px 3px $shadow-color, 4px 4px $shadow-color, 5px 5px $shadow-color,
      6px 6px $shadow-color, 7px 7px $shadow-color, 8px 8px $shadow-color,
      8px 8px $shadow-color;
  }

  & .emoji {
    font-size: 5rem;
    padding-top: 1rem;
    text-shadow: 1px 1px $shadow-color, 2px 2px $shadow-color,
      3px 3px $shadow-color;
  }
}

.diner-menu {
  background-color: white;
  border-radius: 1.5rem;
  // padding: 2rem;
  // padding-top: 0rem;
  max-width: 35em;
  min-height: 50vw;
  margin: 4rem auto;

  @media screen and (max-width: 799px) {
    min-height: 100vw;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  h2 {
    background-color: orangered;
    color: white;
    border-radius: 1.5rem 1.5rem 0 0;
    padding: 3rem;
    text-decoration: dotted underline;

    .subtitle {
      display: block;
      font-size: 1.25rem;
      text-transform: lowercase;
      padding-top: 0.5rem;
    }
  }
}

.diner-menu-title {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
  // text-shadow: 1px 1px $shadow-color, 2px 2px $shadow-color,
  //   3px 3px $shadow-color, 4px 4px $shadow-color, 5px 5px $shadow-color,
  //   6px 6px $shadow-color, 7px 7px $shadow-color, 8px 8px $shadow-color,
  //   8px 8px $shadow-color;
}

.courses {
  padding-bottom: 3rem;
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.course {
  // font-family: "Dancing Script", cursive;
  font-family: "Yanone Kaffeesatz", sans-serif;
  // border: 1rem solid $background-color;
  padding: 0.25rem 0;
  border-radius: 1rem;
  margin: 0 auto;

  & h3 {
    color: orangered;
    font-size: 2rem;
    font-weight: 700;
    padding: 1.5rem 0;

    & .emoji {
      font-size: 2rem;
    }
  }

  & p {
    font-size: 1.5rem;
  }

  & a {
    color: $shadow-color;
    // text-shadow: 1px 1px $shadow-color, 2px 2px $shadow-color,
    //   3px 3px $shadow-color, 4px 4px $shadow-color, 5px 5px $shadow-color,
    //   6px 6px $shadow-color, 7px 7px $shadow-color, 8px 8px $shadow-color,
    //   8px 8px $shadow-color;
    text-decoration: none;

    &:hover {
      color: $sign-color-alt;
    }
  }

  ul.leaders {
    font-size: 1.5rem;
    font-weight: 600;
    max-width: 30em;
    padding: 0;
    overflow-x: hidden;
    list-style: none;
    text-align: left;
    margin: 0 auto;
    text-align: center;

    a {
      display: grid;
      justify-items: center;
    }
  }

  // @media screen and (min-width: 800px) {
  //   ul.leaders {
  //     font-size: 1.5rem;
  //     font-weight: 600;
  //     max-width: 30em;
  //     padding: 0;
  //     overflow-x: hidden;
  //     list-style: none;
  //     text-align: left;
  //     margin: 0 auto;
  //   }
  //   ul.leaders li:before {
  //     float: left;
  //     width: 0;
  //     white-space: nowrap;
  //     content: ". . . . . . . . . . . . . . . . . . . . "
  //       ". . . . . . . . . . . . . . . . . . . . "
  //       ". . . . . . . . . . . . . . . . . . . . "
  //       ". . . . . . . . . . . . . . . . . . . . ";
  //   }
  //   ul.leaders span:first-child {
  //     padding-right: 0.33em;
  //   }
  //   ul.leaders span + span {
  //     float: right;
  //     padding-left: 0.33em;
  //   }
  // }
}

.courses-link {
  // vintage button
  font-size: 2rem;
  background-color: $background-color;
  // border: 0.25rem solid $shadow-color;
  // border: 0.25rem solid white;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  background-color: $sign-color-alt;
  background-color: $shadow-color;
  // color: $shadow-color;
  color: white;
  text-decoration: none;
  // text-shadow: 1px 1px $shadow-color, 2px 2px $shadow-color,
  //   3px 3px $shadow-color, 4px 4px $shadow-color, 5px 5px $shadow-color;

  // font-family: "Yanone Kaffeesatz", sans-serif;
  // font-size: 2rem;
  // font-weight: 700;
  // text-transform: uppercase;
  // text-decoration: underline;
  // color: $shadow-color;
  // text-shadow: 1px 1px $shadow-color, 2px 2px $shadow-color,
  //   3px 3px $shadow-color, 4px 4px $shadow-color, 5px 5px $shadow-color,
  //   6px 6px $shadow-color, 7px 7px $shadow-color, 8px 8px $shadow-color,
  //   8px 8px $shadow-color;
  &:hover {
    background-color: $shadow-color;
    background-color: $sign-color-alt;
  }
}
</style>
